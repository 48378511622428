<ng-container *ngIf="definition$ | async as definition">
  <app-preview-slide-header
    [title]="definition.name"
    [subtitle]="definition.namespace"
    [details]="definition.lastUpdate!"
    (handleBack)="backNavigation()"
    (handleDetailView)="handleDetailView()"></app-preview-slide-header>
  <div class="px-l">
    <div>
      <div class="my-xs text-sm">Status</div>
      <div class="flow space-x-2">
        <app-tag *ngFor="let tag of definition.status; trackBy: trackById" [tag]="tag"></app-tag>
      </div>
    </div>

    <div class="pt-xl grid grid-cols-3">
      <div>
        <div class="my-xs text-sm">Active panelists</div>
      </div>
      <div>
        <div class="my-xs text-sm">Response count</div>
      </div>
      <div>
        <div class="my-xs text-sm">Coverage</div>
      </div>
    </div>
    <div
      class="grid grid-cols-3"
      *ngIf="definitionStats$ | async as definitionStats; else loadingDefinitionStats">
      <div>
        <div class="text-sm font-bold">
          {{ definitionStats.total | number: '1.0':'en' }}
        </div>
      </div>
      <div>
        <div class="text-sm font-bold">
          {{ definitionStats.valid | number: '1.0':'en' }}
        </div>
      </div>
      <div>
        <div class="text-sm font-bold"> {{ definitionStats.coverage | number: '1.2-2' }}% </div>
      </div>
    </div>
    <ng-template #loadingDefinitionStats>
      <div class="grid grid-cols-3">
        <div class="text-sm">-</div>
        <div class="text-sm">-</div>
        <div class="text-sm">-</div>
      </div>
    </ng-template>

    <div class="py-m">
      <div class="my-m text-sm">Question</div>
      <app-qsl-panel [qsl]="definition.qsl" minHeight="250"></app-qsl-panel>
    </div>
  </div>
</ng-container>
