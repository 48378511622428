import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchComponent } from './search.component';
import { UiModule } from '../../ui/ui.module';
import { ReactiveFormsModule } from '@angular/forms';
import { A11yModule } from '@angular/cdk/a11y';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { SearchRowResultComponent } from './search-row-result/search-row-result.component';

@NgModule({
  declarations: [SearchComponent, SearchRowResultComponent],
  imports: [
    CommonModule,
    UiModule,
    ReactiveFormsModule,
    A11yModule,
    MatProgressBarModule,
    RouterModule,
    MatIconModule,
  ],
  exports: [SearchComponent],
})
export class SearchModule {}
