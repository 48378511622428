import { CanActivate } from '@angular/router';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationGuard implements CanActivate {
  constructor(private cookieService: CookieService) {}

  canActivate(): boolean {
    if (this.cookieService.get('session_data')) {
      return true;
    }

    window.location.href = `https://login.yougov.com/?redirect_to=${
      environment.serverHost
    }/auth?origin_url=${encodeURIComponent(environment.serverHost + '/codebooks')}`;
    return true;
  }
}
