import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PreviewPanelRouterWrapperComponent } from './components/preview-panel/preview-panel.component';
import { DefinitionSlideoverComponent } from './definition-slideover/definition-slideover.component';
import { AuthenticationGuard } from './guards/authentication-guard';

const routes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: 'codebooks',
        data: {
          title: 'Codebooks',
        },
        loadChildren: () => import('./codebooks/codebooks.module').then((m) => m.CodebooksModule),
      },
      {
        /**
         * To open the Panel Preview use the following code:
         *
         * Using the Navigate method of the router
         * this.router.navigate([{ outlets: { preview: ['pdl', child_path_element] } }], {queryParamsHandling: 'merge'});
         *
         * Using a direct link
         * <a [routerLink]="['', { outlets: { preview: ['pdl', child_path_element] } }]" queryParamsHandling="preserve">
         *
         * */
        path: 'pdl',
        component: PreviewPanelRouterWrapperComponent,
        outlet: 'preview',
        data: {
          title: 'PDL Preview',
        },
        children: [{ path: ':name/:namespace', component: DefinitionSlideoverComponent }],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
