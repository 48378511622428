import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SelectComponent } from './select.component';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [SelectComponent],
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, MatSelectModule],
  providers: [],
  exports: [SelectComponent],
})
export class SelectModule {}
