import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Definition, DefinitionStats } from '../../interfaces/definition';
import { DefinitionAPI, DefinitionStatsAPI } from '../../interfaces/definition-api';
import { befriendNamespace, replaceParams } from '../../../utils/panel';
import { DefinitionsStore, DefinitionsStatsStore } from './definitions.store';
import { TagType } from '../../interfaces/tag';
import { DatePipe } from '@angular/common';
import { GenericResponseAPI, GenericSearchResultAPI } from '../../interfaces/api';

@Injectable({ providedIn: 'root' })
export class DefinitionsService {
  constructor(
    private http: HttpClient,
    private definitionsStore: DefinitionsStore,
    private definitionsStatsStore: DefinitionsStatsStore,
    private datePipe: DatePipe
  ) {}

  private endpoints = {
    definitions: '/namespaces/:namespace/definitions/',
    definition: '/namespaces/:namespace/definitions/:name',
    definitionStats: '/namespaces/:namespace/definitions/:name/crunch/',
    definitionsLookup: '/codebooks/lookup/',
  };

  get$(name: string, namespace: string): Observable<Definition> {
    return this.http
      .get<DefinitionAPI>(
        environment.apiUrl + replaceParams(this.endpoints.definition, { name, namespace })
      )
      .pipe(
        map((data: DefinitionAPI) => this.mapDefinition(data, namespace)),
        tap((data: Definition) => this.definitionsStore.setLoadedDefinition(data)),
        catchError(this.handleError())
      );
  }

  getDefinitions$(namespace: string): Observable<Definition[]> {
    return this.http
      .get<GenericResponseAPI<DefinitionAPI>>(
        environment.apiUrl + replaceParams(this.endpoints.definitions, { namespace })
      )
      .pipe(
        map((result: GenericResponseAPI<DefinitionAPI>) =>
          this.mapDefinitions(result.objects, namespace)
        )
      );
  }

  getStats$(name: string, namespace: string): Observable<DefinitionStats> {
    return this.http
      .get<DefinitionStatsAPI>(
        environment.apiUrl + replaceParams(this.endpoints.definitionStats, { name, namespace })
      )
      .pipe(
        map((data: DefinitionStatsAPI) => this.mapDefinitionStats(data, name, namespace)),
        tap((data: DefinitionStats) => this.definitionsStatsStore.setLoadedDefinitionStats(data)),
        catchError(this.handleError())
      );
  }

  private mapDefinitions(definitions: DefinitionAPI[], namespace: string): Definition[] {
    return definitions.map((definition) => this.mapDefinition(definition, namespace));
  }

  private mapDefinition(definition: DefinitionAPI, namespace: string): Definition {
    return {
      id: `${definition.name}/${namespace}`,
      name: definition.name,
      namespace: befriendNamespace(namespace),
      description: definition.description,
      status: [
        definition.active
          ? { text: 'Active', type: TagType.green }
          : { text: 'Inactive', type: TagType.grey },
        definition.pii
          ? { text: 'PII', type: TagType.violet }
          : { text: 'Non-PII', type: TagType.violet },
      ],
      qsl: definition.meta.qsl,
      lastUpdate:
        (definition.update_time
          ? 'Last updated ' + this.datePipe.transform(definition.update_time, 'longDate')
          : 'Created ' + this.datePipe.transform(definition.create_time, 'longDate')) +
        ' by ' +
        definition.updated_by,
    };
  }

  private mapDefinitionStats(
    definitionStats: DefinitionStatsAPI,
    name: string,
    namespace: string
  ): DefinitionStats {
    return {
      id: `${name}/${namespace}`,
      valid: definitionStats.valid,
      total: definitionStats.total,
      coverage: (definitionStats.valid / definitionStats.total) * 100,
    };
  }

  searchDefinitions$(namespace: string, searchTerm: string): Observable<Definition[]> {
    /*
    The URL goes
      /api/codebooks/lookup/
      ?type=datum&query=<SEARCH_TERM>&limit=99&offset=0&namespace=<PANEL_NAME>&active=true
    */
    const url =
      environment.apiUrl +
      this.endpoints.definitionsLookup +
      `?type=datum&query=${searchTerm}&attribute=name&namespace=${namespace}&limit=99&offset=0`;

    return this.http.get<GenericSearchResultAPI<DefinitionAPI>>(url).pipe(
      map((results): Definition[] => {
        const { objects } = results.results;
        return this.mapDefinitions(objects, namespace);
      })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError<T>(result?: T): any {
    return (error: Error): Observable<T> => {
      console.log(`DefinitionService: ${error.message}`);
      return result ? of(result as T) : throwError(error);
    };
  }
}
