import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

import classNames from 'classnames';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ui-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  /**
   * Button contents
   *
   * @required
   */
  @Input()
  label = 'Label';

  @Input()
  mode: 'primary' | 'secondary' | 'destructive' = 'primary';

  /**
   * Display as outlined
   */
  @Input()
  outline = false;

  /**
   * Display as only text
   */
  @Input()
  onlyText = false;

  /**
   * What icon to use, shown to the left of the text
   */
  @Input()
  iconLeft = '';

  /**
   * What icon to use, shown to the right of the text
   */
  @Input()
  iconRight = '';

  /**
   * How large should the button be?
   */
  @Input()
  size: 'small' | 'medium' | 'large' = 'medium';

  @Input()
  type: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  /**
   * Optional click handler
   */
  @Output()
  handleClick = new EventEmitter<Event>();

  public get classes(): string {
    return classNames({
      'ui-button': true,
      [`ui-button--text-${this.mode}`]: this.onlyText && !this.outline,
      [`ui-button--outline-${this.mode}`]: !this.onlyText && this.outline,
      [`ui-button--${this.mode}`]: !this.onlyText && !this.outline,
      [`ui-button--${this.size}-icon`]: this.iconRight || this.iconLeft,
      [`ui-button--${this.size}`]: !(this.iconRight || this.iconLeft),
    });
  }
}
