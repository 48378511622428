import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import classNames from 'classnames';
import { coerceBooleanProperty } from '@angular/cdk/coercion';

@Component({
  selector: 'ui-icon-button',
  templateUrl: './icon-button.component.html',
  styleUrls: ['./icon-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconButtonComponent {
  /**
   * Button contents
   *
   * @required
   */
  @Input()
  icon = 'add';

  @Input()
  mode: 'primary' | 'secondary' | 'destructive' = 'primary';

  /**
   * Display as outlined
   */
  @Input()
  outline = false;

  /**
   * Display as only text
   */
  @Input()
  onlyIcon = false;

  /**
   * Display as only text
   */
  @Input()
  shape: 'squared' | 'circular' = 'squared';

  /**
   * How large should the button be?
   */
  @Input()
  size: 'xsmall' | 'small' | 'medium' | 'large' = 'medium';

  @Input()
  type: 'button' | 'submit' | 'reset' = 'button';

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this._disabled = coerceBooleanProperty(value);
  }
  private _disabled = false;

  /**
   * Optional click handler
   */
  @Output()
  handleClick = new EventEmitter<Event>();

  public get classes(): string {
    return classNames({
      'ui-icon-button': true,
      [`ui-icon-button--${this.size}`]: true,
      [`ui-button--text-${this.mode}`]: this.onlyIcon && !this.outline,
      [`ui-icon-button--outline-${this.mode}`]: !this.onlyIcon && this.outline,
      [`ui-icon-button--${this.shape}`]: !this.onlyIcon,
      [`ui-icon-button--${this.mode}`]: !this.onlyIcon && !this.outline,
    });
  }
}
