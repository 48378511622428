<button
  mat-flat-button
  (click)="handleClick.emit($event)"
  [ngClass]="classes"
  [disabled]="disabled"
  [type]="type">
  <mat-icon class="left" *ngIf="iconLeft">
    {{ iconLeft }}
  </mat-icon>
  <span class="label-text">
    {{ label }}
  </span>
  <mat-icon class="right" *ngIf="iconRight">
    {{ iconRight }}
  </mat-icon>
</button>
