import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  PreviewPanelComponent,
  PreviewPanelRouterWrapperComponent,
} from './preview-panel.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [PreviewPanelComponent, PreviewPanelRouterWrapperComponent],
  imports: [CommonModule, MatSidenavModule, RouterModule],
  exports: [PreviewPanelComponent, PreviewPanelRouterWrapperComponent],
})
export class PreviewPanelModule {}
