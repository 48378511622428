import { ChangeDetectionStrategy, Component, Input, ViewEncapsulation } from '@angular/core';
import { NotificationsService } from '../../state/notifications/notifications.service';

@Component({
  selector: 'app-qsl-panel',
  templateUrl: './qsl-panel.component.html',
  styleUrls: ['./qsl-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class QslPanelComponent {
  @Input() qsl!: string;

  @Input() minHeight = '200';

  constructor(private notificationsService: NotificationsService) {}

  handleCopy(): void {
    this.notificationsService.open('Copied', 'Dismiss');
  }
}
