import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-preview-panel',
  templateUrl: './preview-panel.component.html',
  styleUrls: ['./preview-panel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewPanelComponent {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef
  ) {}
  @ViewChild('sidenav') sidenav!: MatSidenav;

  onActivate(): void {
    setTimeout(() => {
      this.cd.markForCheck();
      this.sidenav.open();
    });
  }

  onDeactivate(): void {
    this.sidenav.close();
  }

  onSidenavClose(): void {
    this.router.navigate(['.', { outlets: { preview: null } }], {
      relativeTo: this.route.parent,
      queryParamsHandling: 'merge',
    });
  }
}

@Component({
  template: `<router-outlet></router-outlet>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreviewPanelRouterWrapperComponent {}
