import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { Definition, DefinitionStats } from '../interfaces/definition';
import { DefinitionsService } from '../state/definitions/definitions.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { DefinitionsQuery, DefinitionsStatsQuery } from '../state/definitions/definitions.query';
import { NotificationsService } from '../state/notifications/notifications.service';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-definition-slideover',
  templateUrl: './definition-slideover.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DefinitionSlideoverComponent implements OnInit {
  name = this.route.snapshot.params.name;
  namespace = this.route.snapshot.params.namespace;

  definition$: Observable<Definition | undefined> = this.definitionQuery.selectEntity(
    `${this.name}/${this.namespace}`
  );
  definitionStats$: Observable<DefinitionStats | undefined> =
    this.definitionStatsQuery.selectEntity(`${this.name}/${this.namespace}`);

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private definitionQuery: DefinitionsQuery,
    private definitionStatsQuery: DefinitionsStatsQuery,
    private definitionService: DefinitionsService,
    private notificationsService: NotificationsService
  ) {}

  ngOnInit(): void {
    if (this.route.snapshot.params.name && this.route.snapshot.params.namespace) {
      const { name, namespace } = this.route.snapshot.params;
      this.definition$ = this.definitionService
        .get$(name, namespace)
        .pipe(catchError(this.handleError()));

      this.definitionStats$ = this.definitionService.getStats$(name, namespace);
    }
  }

  backNavigation(): void {
    this.router.navigate(['.', { outlets: { preview: null } }], {
      queryParamsHandling: 'merge',
    });
  }

  trackById(index: number): number {
    return index;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private handleError<T>(result?: T): any {
    return (error: Error): Observable<T> => {
      this.router.navigate(['.', { outlets: { preview: null } }], {
        queryParamsHandling: 'merge',
      });
      this.notificationsService.open('Definition not found', 'Dismiss');
      return result ? of(result as T) : throwError(error);
    };
  }

  handleDetailView(): void {
    if (this.route.snapshot.params.name && this.route.snapshot.params.namespace) {
      const { name, namespace } = this.route.snapshot.params;
      window.location.href = `${environment.serverHost}/namespaces/${namespace}/definitions/${name}`;
    }
  }
}
