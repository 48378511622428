<div class="app-preview-slide-header bg-white divide-solid divide-y px-l">
  <div class="flex justify-between py-m">
    <div class="flex justify-between">
      <div class="space-y-xs">
        <div class="flex justify-center">
          <div class="flex items-center">
            <ui-icon-button
              mode="secondary"
              class="back-button"
              icon="west"
              size="small"
              [onlyIcon]="true"
              (handleClick)="handleBack.emit($event)"></ui-icon-button>
            <ui-text>
              <h2
                class="text-sm text-plum-1200"
                [ngClass]="{ 'font-weight-bold': emphasizeNamespace }">
                {{ title }}
              </h2>
            </ui-text>
          </div>
        </div>

        <div class="flex items-center">
          <div class="dot-icon"></div>
          <span class="text-xs subtitle-component mx-xs">
            {{ subtitle }}
          </span>
        </div>
      </div>
    </div>
    <div class="flex flex-wrap content-center">
      <ui-button
        mode="secondary"
        [outline]="false"
        [onlyText]="false"
        iconRight="east"
        size="small"
        type="button"
        [label]="buttonLabel"
        (handleClick)="handleDetailView.emit($event)"></ui-button>
    </div>
  </div>

  <div class="flex py-m" *ngIf="details">
    <span class="text-xs text-plum-700">
      {{ details }}
    </span>
  </div>
</div>
