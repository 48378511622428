import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';

@Injectable({ providedIn: 'root' })
export class NotificationsService {
  constructor(private snackBar: MatSnackBar) {}

  private defaults: MatSnackBarConfig = {
    verticalPosition: 'bottom',
    horizontalPosition: 'center',
    duration: 5000,
  };

  open(message: string, action: string = 'OK', config?: MatSnackBarConfig): void {
    this.snackBar.open(message, action, {
      ...this.defaults,
      ...config,
    });
  }
}
