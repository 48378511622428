import { NgModule } from '@angular/core';
import { SidepanelComponent } from './sidepanel.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { RouterModule } from '@angular/router';
import { UiModule } from '../../ui/ui.module';

@NgModule({
  declarations: [SidepanelComponent],
  imports: [MatSidenavModule, MatListModule, RouterModule, UiModule],
  exports: [SidepanelComponent],
})
export class SidepanelModule {}
