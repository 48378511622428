import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from '../../environments/environment';
import { JwtHelperService } from '@auth0/angular-jwt';
import { CookieService } from 'ngx-cookie';
import { JwtPayload } from 'jsonwebtoken';

@Component({
  selector: 'app-top-bar',
  templateUrl: './top-bar.component.html',
  styleUrls: ['./top-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarComponent implements OnInit {
  tokenPayload: JwtPayload;

  initials = 'n/a';

  constructor(private cookieService: CookieService) {
    const jwtHelper = new JwtHelperService();
    this.tokenPayload = jwtHelper.decodeToken(cookieService.get('session_data'));
  }

  ngOnInit(): void {
    // Disabling Beamer sometimes needed due to a bug in E2E tests tracked here:
    // https://github.com/DevExpress/testcafe/issues/6631
    if (environment.beamerId && environment.beamerChannel) {
      this.loadBeamerConfig();
      this.loadBeamerScript();
    }

    if (this.tokenPayload) {
      this.setInitials();
    }
  }

  loadBeamerConfig(): void {
    /* eslint-disable @typescript-eslint/naming-convention */
    const beamer = {
      product_id: environment.beamerId,
      filter: environment.beamerChannel,
      selector: 'beamerButton',
      display: 'right',
      bounce: false,
      top: 6,
      right: 10,
      // TODO fill user data
      // user_email: user.email,
      // user_firstname: user.firstname,
      // user_lastname: user.lastname
    };
    const script = document.createElement('script');
    script.text = `var beamer_config = ${JSON.stringify(beamer)}`;
    document.body.appendChild(script);
  }

  loadBeamerScript(): void {
    const script = document.createElement('script');
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('defer', 'defer');
    script.setAttribute('src', 'https://app.getbeamer.com/js/beamer-embed.js');
    document.body.appendChild(script);
  }

  setInitials(): void {
    const { firstname, lastname } = this.tokenPayload.user
      ? this.tokenPayload.user
      : this.tokenPayload;
    if (!firstname || !lastname) {
      return;
    }
    this.initials = firstname.charAt(0).toUpperCase() + lastname.charAt(0).toUpperCase();
  }
}
