import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UnavailableComponent } from './unavailable.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [UnavailableComponent],
  imports: [CommonModule, MatSnackBarModule],
  providers: [],
  exports: [UnavailableComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class UnavailableModule {}
