import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatToolbarModule } from '@angular/material/toolbar';

import { UiModule } from '../../../ui/ui.module';

import { GlobalPromptComponent } from './global-prompt.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';

@NgModule({
  declarations: [GlobalPromptComponent],
  imports: [CommonModule, MatToolbarModule, MatProgressBarModule, UiModule],
  exports: [GlobalPromptComponent],
})
export class GlobalPromptModule {}
