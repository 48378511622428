import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../../ui/ui.module';
import { QslPanelComponent } from './qsl-panel.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

@NgModule({
  declarations: [QslPanelComponent],
  imports: [CommonModule, UiModule, ClipboardModule],
  exports: [QslPanelComponent],
})
export class QslPanelModule {}
