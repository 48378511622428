import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UiModule } from '../ui/ui.module';
import { TopBarModule } from './top-bar/top-bar.module';
import { SidepanelModule } from './sidepanel/sidepanel.module';
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools';
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store';
import { environment } from '../environments/environment';
import { SearchModule } from './search/search.module';
import { GlobalPromptModule } from './components/global-prompt/global-prompt.module';
import * as Sentry from '@sentry/angular';
import { Router } from '@angular/router';
import { PreviewPanelModule } from './components/preview-panel/preview-panel.module';
import { DefinitionSlideoverModule } from './definition-slideover/definition-slideover.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TopBarModule,
    SearchModule,
    SidepanelModule,
    UiModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
    AkitaNgRouterStoreModule,
    GlobalPromptModule,
    PreviewPanelModule,
    DefinitionSlideoverModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
