export const extractNamespace = (path: string): string => {
  const [namespace] = path.endsWith('/') ? path.split('/').slice(-2) : path.split('/').slice(-1);
  return namespace;
};

export const befriendNamespace = (namespace: string | undefined): string | undefined => {
  if (!namespace || !namespace.includes(':')) {
    return namespace;
  }
  const [label, id] = namespace.split(':');
  return `${label[0].toUpperCase()}${label.slice(1)} ${id}`;
};

export const replaceParams = (str: string, params: Record<string, unknown>): string => {
  return str.replace(/(^|\/):(\w+)(?=\/|$)/g, (m, g1, g2) => g1 + (params[g2] || m));
};
