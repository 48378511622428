import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { DefinitionSlideoverComponent } from './definition-slideover.component';
import { PreviewSlideHeaderModule } from '../preview-slide-header/preview-slide-header.module';
import { QslPanelModule } from '../components/qsl-panel/qsl-panel.module';
import { TagModule } from '../tag/tag.module';

@NgModule({
  declarations: [DefinitionSlideoverComponent],
  imports: [CommonModule, PreviewSlideHeaderModule, QslPanelModule, TagModule],
  exports: [DefinitionSlideoverComponent],
  providers: [DatePipe],
})
export class DefinitionSlideoverModule {}
