import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Tag, TagType } from '../interfaces/tag';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TagComponent {
  @Input() tag: Tag = {
    text: '',
    type: TagType.teal,
  };
}
