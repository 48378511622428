import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UiModule } from '../../ui/ui.module';
import { PreviewSlideHeaderComponent } from './preview-slide-header.component';

@NgModule({
  declarations: [PreviewSlideHeaderComponent],
  imports: [CommonModule, UiModule],
  exports: [PreviewSlideHeaderComponent],
})
export class PreviewSlideHeaderModule {}
