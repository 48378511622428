import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
} from '@angular/core';

@Component({
  selector: 'app-preview-slide-header',
  templateUrl: './preview-slide-header.component.html',
  styleUrls: ['./preview-slide-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class PreviewSlideHeaderComponent {
  @Input() title: string | null = null;
  @Input() subtitle?: string | null = null;
  @Input() details: string | null = null;
  @Input() emphasizeNamespace = true;

  buttonLabel = 'View';

  @Output()
  handleBack = new EventEmitter<Event>();

  @Output()
  handleDetailView = new EventEmitter<Event>();
}
