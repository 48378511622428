import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatFormFieldModule } from '@angular/material/form-field';

import { ButtonModule } from './button/button.module';
import { IconButtonModule } from './icon-button/icon-button.module';
import { CheckboxModule } from './checkbox/checkbox.module';
import { ChipAutocompleteModule } from './chip-autocomplete/chip-autocomplete.module';
import { InputModule } from './input/input.module';
import { RadioModule } from './radio/radio.module';
import { SelectModule } from './select/select.module';
import { SelectAutocompleteModule } from './select-autocomplete/select-autocomplete.module';
import { TextareaModule } from './textarea/textarea.module';
import { TextModule } from './text/text.module';
import { TableModule } from './table/table.module';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { IconModule } from './icon/icon.module';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    ButtonModule,
    CheckboxModule,
    ChipAutocompleteModule,
    IconButtonModule,
    IconModule,
    InputModule,
    RadioModule,
    SelectModule,
    SelectAutocompleteModule,
    TextareaModule,
    TextModule,
    TableModule,
  ],
  providers: [],
  exports: [
    ButtonModule,
    CheckboxModule,
    ChipAutocompleteModule,
    IconButtonModule,
    IconModule,
    InputModule,
    RadioModule,
    SelectModule,
    SelectAutocompleteModule,
    TextareaModule,
    TextModule,
    TableModule,
    EllipsisPipe,
  ],
  declarations: [EllipsisPipe],
})
export class UiModule {}
