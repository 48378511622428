import { Injectable } from '@angular/core';
import { QueryEntity } from '@datorama/akita';
import {
  DefinitionsState,
  DefinitionsStore,
  DefinitionsStatsState,
  DefinitionsStatsStore,
} from './definitions.store';

@Injectable({ providedIn: 'root' })
export class DefinitionsQuery extends QueryEntity<DefinitionsState> {
  constructor(protected store: DefinitionsStore) {
    super(store);
  }
}

@Injectable({ providedIn: 'root' })
export class DefinitionsStatsQuery extends QueryEntity<DefinitionsStatsState> {
  constructor(protected store: DefinitionsStatsStore) {
    super(store);
  }
}
