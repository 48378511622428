<mat-sidenav-container class="app-sidepanel-container">
  <mat-sidenav mode="side" opened class="app-sidepanel-sidenav">
    <mat-nav-list class="app-sidepanel-navlist" disableRipple>
      <mat-list-item routerLinkActive="active" [routerLinkActiveOptions]="{ exact: true }">
        <a [href]="environment.serverHost">
          <ui-text>Dashboard</ui-text>
        </a>
      </mat-list-item>

      <mat-list-item>
        <a [href]="environment.serverHost + '/global_definitions'">
          <ui-text>Global Definitions</ui-text>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a [href]="environment.serverHost + '/global_tree'">
          <ui-text>Global Tree</ui-text>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a [href]="environment.serverHost + '/namespaces'">
          <ui-text>Namespaces</ui-text>
        </a>
      </mat-list-item>

      <mat-list-item routerLinkActive="active">
        <a [routerLink]="['codebooks']">
          <ui-text>Codebooks</ui-text>
        </a>
      </mat-list-item>

      <mat-list-item>
        <a [href]="environment.serverHost + '/search'">
          <ui-text>Search</ui-text>
        </a>
      </mat-list-item>

      <mat-list-item>
        <a [href]="environment.serverHost + '/notifications'">
          <ui-text>Notifications</ui-text>
        </a>
      </mat-list-item>

      <mat-list-item>
        <a [href]="environment.serverHost + '/pending_drafts'">
          <ui-text>Pending Drafts</ui-text>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a [href]="environment.serverHost + '/matrixes'">
          <ui-text>Matrixes</ui-text>
        </a>
      </mat-list-item>

      <!-- <mat-list-item routerLinkActive="active">
        <a [routerLink]="['definitions']">
          <ui-text>Definitions</ui-text>
        </a>
      </mat-list-item> -->
      <!-- <mat-list-item routerLinkActive="active">
        <a [routerLink]="['panels']">
          <ui-text>Panels</ui-text>
        </a>
      </mat-list-item> -->
    </mat-nav-list>
    <mat-nav-list class="app-sidepanel-footerlinks" disableRipple>
      <mat-list-item>
        <a href="https://datum.yougov.net/docs/" target="_blank">
          <ui-text>Documentation</ui-text>
        </a>
      </mat-list-item>
      <mat-list-item>
        <a routerLink="#">
          <ui-text>Help</ui-text>
        </a>
      </mat-list-item>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content class="z-10">
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
