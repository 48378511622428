export enum TagType {
  teal = 'teal',
  green = 'green',
  violet = 'violet',
  blue = 'blue',
  grey = 'grey',
}

export interface Tag {
  text: string;
  type: TagType;
}
