<div class="app-qsl-panel border divide-solid divide-y">
  <div class="panel-header px-3 py-2 flex items-center justify-between">
    <ui-text class="text-xs text-plum-1000 font-weight-bold"> QSL </ui-text>
    <ui-icon-button
      mode="secondary"
      class="copy-icon"
      icon="filter_none"
      size="xsmall"
      [onlyIcon]="true"
      (handleClick)="handleCopy()"
      [cdkCopyToClipboard]="qsl"></ui-icon-button>
  </div>
  <div class="qsl-content px-3 py-2">
    <textarea
      readonly
      class="w-full text-xs text-plum-1200 font-weight-bold"
      [ngStyle]="{ 'min-height': minHeight + 'px' }">
      {{ qsl }}
    </textarea>
  </div>
</div>
