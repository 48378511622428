import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { TextareaComponent } from './textarea.component';

@NgModule({
  declarations: [TextareaComponent],
  imports: [CommonModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, MatInputModule],
  providers: [],
  exports: [TextareaComponent],
})
export class TextareaModule {}
