import { Injectable } from '@angular/core';
import { action, EntityState, EntityStore, StoreConfig, transaction } from '@datorama/akita';
import { Definition, DefinitionStats } from '../../interfaces/definition';

export type DefinitionsState = EntityState<Definition>;
export type DefinitionsStatsState = EntityState<DefinitionStats>;

export function createInitialDefinitionsState(): DefinitionsState {
  return {};
}

export function createInitialStatsState(): DefinitionsStatsState {
  return {};
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'definitions' })
export class DefinitionsStore extends EntityStore<DefinitionsState> {
  constructor() {
    super(createInitialDefinitionsState());
  }

  @transaction()
  @action('Set Loaded Definition')
  setLoadedDefinition(definition: Definition): void {
    this.upsert(
      definition.id,
      (existing) => ({
        ...existing,
        ...definition,
      }),
      (id, newState) => ({ ...newState, id })
    );
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'definitionsStats' })
export class DefinitionsStatsStore extends EntityStore<DefinitionsStatsState> {
  constructor() {
    super(createInitialStatsState());
  }

  @transaction()
  @action('Set Loaded DefinitionStats')
  setLoadedDefinitionStats(definitionStats: DefinitionStats): void {
    this.upsert(
      definitionStats.id,
      (existing) => ({
        ...existing,
        ...definitionStats,
      }),
      (id, newState) => ({ ...newState, id })
    );
  }
}
