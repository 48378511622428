<mat-sidenav-container hasBackdrop="true">
  <mat-sidenav-content>
    <ng-content></ng-content>
  </mat-sidenav-content>
  <mat-sidenav #sidenav mode="over" position="end" (closed)="onSidenavClose()">
    <div class="content-wrap">
      <router-outlet
        name="preview"
        (activate)="onActivate()"
        (deactivate)="onDeactivate()"></router-outlet>
    </div>
  </mat-sidenav>
</mat-sidenav-container>
