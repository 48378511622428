<mat-toolbar class="app-top-bar">
  <button class="logo mr-s flex flex-col items-center justify-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20"
      height="20"
      viewBox="0 0 20 20">
      <defs>
        <path
          id="7nb2k7d5ba"
          d="M13.333 13.333v3.75h3.75v-3.75h-3.75zm5.834-2.083v7.917H11.25V11.25h7.917zm-10.417 0v7.917H.833V11.25H8.75zm-2.083 2.083h-3.75v3.75h3.75v-3.75zM8.75.833V8.75H.833V.833H8.75zm10.417 0V8.75H11.25V.833h7.917zm-12.5 2.084h-3.75v3.75h3.75v-3.75zm10.416 0h-3.75v3.75h3.75v-3.75z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g transform="translate(-18.000000, -18.000000) translate(18.000000, 18.000000)">
            <mask id="baljs18ajb" fill="#fff">
              <use xlink:href="#7nb2k7d5ba" />
            </mask>
            <use fill="#241D36" fill-rule="nonzero" xlink:href="#7nb2k7d5ba" />
            <g fill="#FFF" mask="url(#baljs18ajb)">
              <path d="M0 0H20V20H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
  <h1 class="title" aria-label="YouGov Datum">
    <span class="font-weight-bold">YouGov</span><span class="font-weight-light">Datum</span>
  </h1>
  <span class="top-bar-spacer"></span>
  <!-- TODO :: Placeholder, replace with Beamer icon -->
  <button
    id="beamerButton"
    mat-button
    class="beamer-icon flex flex-col items-center justify-center">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="20"
      height="21"
      viewBox="0 0 20 21">
      <defs>
        <path
          id="z6pdcxuioa"
          d="M8.409 15.1l.522 1.805c.087.297.513.595 1.086.595.573 0 .999-.298 1.085-.595l.522-1.805h3.74c-1.345-1.962-1.962-4.31-1.962-7.891 0-1.244-.93-2.592-2.392-2.947l-1.003-.243-.997.237c-1.47.349-2.423 1.718-2.423 2.953 0 3.576-.61 5.933-1.936 7.891H8.41zM6.53 17.6H1.894L.417 16c1.707-1.442 3.67-3.218 3.67-8.791 0-1.91 1.328-4.669 4.345-5.386-.01-.073-.015-.147-.015-.223 0-.884.716-1.6 1.6-1.6.883 0 1.6.716 1.6 1.6 0 .08-.006.157-.017.233 3.008.73 4.302 3.467 4.302 5.376 0 5.573 2.007 7.349 3.715 8.791l-1.477 1.6h-4.637c-.4 1.38-1.809 2.4-3.486 2.4-1.678 0-3.087-1.02-3.487-2.4z" />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <g>
          <g transform="translate(-1316.000000, -18.000000) translate(1316.000000, 18.058594)">
            <mask id="ctpx96a5xb" fill="#fff">
              <use xlink:href="#z6pdcxuioa" />
            </mask>
            <use fill="#241D36" fill-rule="nonzero" xlink:href="#z6pdcxuioa" />
            <g fill="#605970" mask="url(#ctpx96a5xb)">
              <path d="M0 0H20V20H0z" />
            </g>
          </g>
        </g>
      </g>
    </svg>
  </button>
  <button
    mat-button
    [matMenuTriggerFor]="profileMenu"
    id="profile-menu"
    class="pr-m"
    aria-label="Profile icon with a menu">
    <div class="profile-initials mr-xxs">{{ initials }}</div>
  </button>
  <mat-menu #profileMenu="matMenu" xPosition="before">
    <a mat-menu-item href="/profile">Profile</a>
    <a mat-menu-item href="/profile/logout/">Log out</a>
  </mat-menu>
</mat-toolbar>
