<button
  mat-flat-button
  (click)="handleClick.emit($event)"
  [ngClass]="classes"
  [disabled]="disabled"
  [type]="type">
  <mat-icon>
    {{ icon }}
  </mat-icon>
</button>
