export const environment = {
  name: 'Staging',
  releaseVersion: 'datum-ui@d4340bdb',
  production: true,
  serverHost: 'http://datum-staging-cdn.yougov.com',
  apiUrl: 'https://datum-staging-web4.wild.yougov.net/api',
  sentryUrl: 'https://3ca5daf3599349ae85f924304e608f45@o23718.ingest.sentry.io/6020864',
  beamerId: '',
  beamerChannel: '',
  assetsPath: 'new/assets',
};
